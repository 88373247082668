import { ClassNames } from "@rocketmakers/armstrong-edge";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Shell from "../layout/shell";

import * as styles from "./services.module.scss";

const Services: React.FC<PageProps<Queries.ServicesPageQuery>> = (
  pageProps
) => {
  const data = pageProps.data.servicesPage?.data;

  if (!data) {
    return null;
  }

  const hero = data.hero && getImage(data.hero?.gatsbyImageData);

  return (
    <Shell>
      <div className={ClassNames.concat("fixed-width", styles.servicesView)}>
        <h2>{data.intro}</h2>
        {hero && (
          <div className={styles.heroWrapper}>
            <GatsbyImage
              className={styles.hero}
              image={hero}
              alt={data.hero.alt || ""}
            />
            <label>{data.hero.alt}</label>
          </div>
        )}
        <div className={styles.blocks}>
          {data.detail_blocks?.map((block) => (
            <div key={block?.detail_block_title} className={styles.block}>
              <h4>{block?.detail_block_subtitle}</h4>
              <h3>{block?.detail_block_title}</h3>
              <p>{block?.detail_block_copy}</p>
              <strong>Services</strong>
              <p className={styles.services}>{block?.services}</p>
            </div>
          ))}
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query ServicesPage {
    servicesPage: prismicWhatIDoPage {
      data {
        title
        intro
        hero {
          gatsbyImageData
          alt
        }
        detail_blocks {
          detail_block_title
          detail_block_subtitle
          detail_block_copy
          services
        }
      }
    }
  }
`;

export default Services;
